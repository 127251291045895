/* eslint-disable no-prototype-builtins */
import React, { ReactElement, useState, useEffect, useCallback } from 'react'
import './InquireNow.scss'
import loadable from '@loadable/component'
import { navigate } from 'gatsby'
import Seo from '../../components/Seo/Seo'

import { addFormValidation } from '../../components/Validators/InputValidations'
import { removeDefaultSE } from '../../services/snapengage'

import inquireImage from '../../assets/images/inquire_now-placeholder.png'
import inquireLogo from '../../assets/images/inquireLogo.png'
import chatBox from '../../assets/images/icons/chat-box.png'
import key from '../../assets/images/icons/key.png'
import car from '../../assets/images/icons/car.png'
import headphone from '../../assets/images/icons/headphone.png'
import greenAfterSales from '../../assets/images/icons/green-after-sales.png'
import homeFinancing from '../../assets/images/icons/home-financing.png'

const Layout = loadable(() => import('../../components/Layout/Layout'))
const Modal = loadable(() => import('../../components/Modal/Modal'))
const ContactDetails = loadable(() =>
  import('../../components/ContactDetails/ContactDetails'),
)

declare global {
  interface Window {
    hbspt?: {
      forms: {
        create(option: {
          portalId: string
          formId: string
          target: string
          onFormReady: () => void
        }): void
      }
    }
    jQuery?: {}
  }
}

const InquireNow = (): ReactElement => {
  const [isDetailsShowingProject, setIsDetailsShowingProject] = useState<
    boolean
  >(false)
  const [isDetailsShowingTurnover, setIsDetailsShowingTurnover] = useState<
    boolean
  >(false)
  const [isDetailsShowingTripping, setIsDetailsShowingTripping] = useState<
    boolean
  >(false)
  const [isDetailsShowingCustomer, setIsDetailsShowingCustomer] = useState<
    boolean
  >(false)
  const [isDetailsShowingAfterSales, setIsDetailsShowingAfterSales] = useState<
    boolean
  >(false)

  const handleFormReady = useCallback(() => {
    // Project Inquiry Form
    addFormValidation('project-inquiry-form', [
      {
        elementId: 'firstname-6a5a285c-a73b-4b0e-8bd8-87c05b62fc8d',
        validationType: 'alphaOnly',
      },
      {
        elementId: 'phone-6a5a285c-a73b-4b0e-8bd8-87c05b62fc8d',
        validationType: 'numericOnly',
      },
      {
        elementId: 'message-6a5a285c-a73b-4b0e-8bd8-87c05b62fc8d',
        validationType: 'alphaNumericWithSpecialCharacters',
      },
    ])

    // Unit Turnover Form
    addFormValidation('unit-turnover-form', [
      {
        elementId: 'firstname-8d8fbd15-c5d6-4556-a6c6-7766c8a9815b',
        validationType: 'alphaOnly',
      },
      {
        elementId: 'phone-8d8fbd15-c5d6-4556-a6c6-7766c8a9815b',
        validationType: 'numericOnly',
      },
      {
        elementId: 'address-8d8fbd15-c5d6-4556-a6c6-7766c8a9815b',
        validationType: 'alphaNumericWithSpecialCharacters',
      },
      {
        elementId: 'block_tower_building-8d8fbd15-c5d6-4556-a6c6-7766c8a9815b',
        validationType: 'alphaNumericWithSpecialCharacters',
      },
      {
        elementId: 'message-8d8fbd15-c5d6-4556-a6c6-7766c8a9815b',
        validationType: 'alphaNumericWithSpecialCharacters',
      },
    ])

    // Tripping Request
    addFormValidation('tripping-request-form', [
      {
        elementId: 'firstname-de263d03-35fb-4b24-938d-59f2f9d8f21c',
        validationType: 'alphaOnly',
      },
      {
        elementId: 'phone-de263d03-35fb-4b24-938d-59f2f9d8f21c',
        validationType: 'numericOnly',
      },
      {
        elementId: 'message-de263d03-35fb-4b24-938d-59f2f9d8f21c',
        validationType: 'alphaNumericWithSpecialCharacters',
      },
    ])

    // Customer Care Request
    addFormValidation('customer-care-form', [
      {
        elementId: 'firstname-2489c44b-0ce8-4095-9522-2168544e12ed',
        validationType: 'alphaOnly',
      },
      {
        elementId: 'phone-2489c44b-0ce8-4095-9522-2168544e12ed',
        validationType: 'numericOnly',
      },
      {
        elementId: 'message-2489c44b-0ce8-4095-9522-2168544e12ed',
        validationType: 'alphaNumericWithSpecialCharacters',
      },
    ])

    const contacNumbers: any = [
      'phone-6a5a285c-a73b-4b0e-8bd8-87c05b62fc8d',
      'phone-8d8fbd15-c5d6-4556-a6c6-7766c8a9815b',
      'phone-de263d03-35fb-4b24-938d-59f2f9d8f21c',
      'phone-2489c44b-0ce8-4095-9522-2168544e12ed',
    ]

    // add maxlength in contact number
    Object.keys(contacNumbers).forEach((i) => {
      const addMaxLengthContacNo: HTMLElement | null = document.getElementById(
        contacNumbers[i].toString(),
      )

      const isElement = (input: HTMLElement | null): input is HTMLElement =>
        input !== null

      if (isElement(addMaxLengthContacNo)) {
        addMaxLengthContacNo.setAttribute('maxLength', '12')
      }
    })
  }, [])

  useEffect(() => {
    window.jQuery =
      window.jQuery ||
      ((): { change(): null; trigger(): null } => ({
        change: (): null => null,
        trigger: (): null => null,
      }))
  }, [])

  useEffect(() => {
    if (isDetailsShowingProject && window.hbspt?.forms) {
      window.hbspt.forms.create({
        portalId: '8605476',
        formId: '6a5a285c-a73b-4b0e-8bd8-87c05b62fc8d',
        target: '#project-inquiry-form',
        onFormReady: handleFormReady,
      })
    }
  }, [handleFormReady, isDetailsShowingProject])

  useEffect(() => {
    if (isDetailsShowingTurnover && window.hbspt?.forms) {
      window.hbspt.forms.create({
        portalId: '8605476',
        formId: '8d8fbd15-c5d6-4556-a6c6-7766c8a9815b',
        target: '#unit-turnover-form',
        onFormReady: handleFormReady,
      })
    }
  }, [handleFormReady, isDetailsShowingTurnover])

  useEffect(() => {
    if (isDetailsShowingTripping && window.hbspt?.forms) {
      window.hbspt.forms.create({
        portalId: '8605476',
        formId: 'de263d03-35fb-4b24-938d-59f2f9d8f21c',
        target: '#tripping-request-form',
        onFormReady: handleFormReady,
      })
    }
  }, [handleFormReady, isDetailsShowingTripping])

  useEffect(() => {
    if (isDetailsShowingCustomer && window.hbspt?.forms) {
      window.hbspt.forms.create({
        portalId: '8605476',
        formId: '2489c44b-0ce8-4095-9522-2168544e12ed',
        target: '#customer-care-form',
        onFormReady: handleFormReady,
      })
    }
  }, [handleFormReady, isDetailsShowingCustomer])

  useEffect(() => {
    removeDefaultSE()
  }, [])

  const inquireItems = [
    {
      title: 'PROJECT INQUIRY',
      image: chatBox,
      id: 'project-inquiry-form',
      modal: isDetailsShowingProject,
      closeModal: setIsDetailsShowingProject,
    },
    {
      title: 'UNIT TURNOVER',
      image: key,
      id: 'unit-turnover-form',
      modal: isDetailsShowingTurnover,
      closeModal: setIsDetailsShowingTurnover,
      tagline:
        'For all concerns about Unit Turnover, please contact the following:',
      telephone: '(+632) 8848-5000',
      email: 'customerrelations@amaialand.com',
    },
    {
      title: 'TRIPPING REQUESTS',
      image: car,
      id: 'tripping-request-form',
      modal: isDetailsShowingTripping,
      closeModal: setIsDetailsShowingTripping,
    },
    {
      title: 'CUSTOMER CARE',
      image: headphone,
      id: 'customer-care-form',
      modal: isDetailsShowingCustomer,
      closeModal: setIsDetailsShowingCustomer,
      tagline: 'For all client concerns, please contact the following:',
      telephone: '(+632) 8848-5000',
      email: 'customerrelations@amaialand.com',
    },
    {
      title: 'AFTER SALES',
      image: greenAfterSales,
      tagline:
        'Chat with our customer service representative for your queries regarding your property purchase with Amaia Land.',
      tagline2: 'You can also reach us through this channel:',
      id: 'customer-care-form',
      modal: isDetailsShowingAfterSales,
      closeModal: setIsDetailsShowingAfterSales,
      telephone: 'One RBG Hotline: (+632) 8848-5000',
      email: 'residential.customers@ayalaland.com.ph',
    },
    {
      title: 'HOME FINANCING',
      image: homeFinancing,
      navigate: (): void => {
        navigate('/home-financing')
      },
    },
  ]

  return (
    <Layout>
      <Seo
        title="Inquire Now"
        jsonData={{
          '@type': 'Website',
          description: 'Inquire Now page',
        }}
      />
      <div className="inquire-now">
        <div className="inquire-now-content">
          <h4 className="title">
            Welcome to <img src={inquireLogo} alt="inquire logo" />
          </h4>
          <h4 className="description">
            Ask Amaia is an online portal to everything you need to know about
            Amaia&apos;s projects and services.
          </h4>
          <div className="inquire-items">
            {inquireItems.slice(0, 2).map((option) => {
              return (
                <button
                  key={option.title}
                  type="button"
                  onClick={(): void => option.closeModal?.(true)}
                  className="options"
                >
                  <img src={option.image} alt="inquire items" />
                  <p className="label">{option.title}</p>
                </button>
              )
            })}
          </div>
          <div className="inquire-items">
            {inquireItems.slice(2, 4).map((option) => {
              return (
                <button
                  key={option.title}
                  type="button"
                  onClick={(): void => option.closeModal?.(true)}
                  className="options"
                >
                  <img src={option.image} alt="inquire items" />
                  <p className="label">{option.title}</p>
                </button>
              )
            })}
          </div>
          <div className="inquire-items">
            {inquireItems.slice(4, -1).map((option) => {
              return (
                <button
                  key={option.title}
                  type="button"
                  onClick={(): void => option.closeModal?.(true)}
                  className="options"
                >
                  <img src={option.image} alt="inquire items" />
                  <p className="label">{option.title}</p>
                </button>
              )
            })}
            {inquireItems.slice(5).map((option) => {
              return (
                <button
                  key={option.title}
                  type="button"
                  onClick={(): void => option?.navigate?.()}
                  className="options"
                >
                  <img src={option.image} alt="inquire items" />
                  <p className="label">{option.title}</p>
                </button>
              )
            })}
          </div>
        </div>
        <div className="inquire-now-image">
          <img src={inquireImage} alt="tempImage" />
        </div>
        <div className="slider-nav-container" />
      </div>

      <div>
        {inquireItems.map((option) => {
          return (
            <Modal
              className="left"
              noIcons
              side
              isShowing={option.modal}
              onClose={(): void => option.closeModal?.(false)}
              key={option.title}
            >
              <div className="inquire-now-form">
                <h4 className="title">{option.title}</h4>
                <h4 className="tagline">Ask Amaia</h4>
                {option.hasOwnProperty('telephone') ? (
                  <ContactDetails
                    tagline={option.tagline}
                    tagline2={option.tagline2}
                    telephone={option.telephone!}
                    email={option.email!}
                    title={option.title}
                    closeModal={(): void => option.closeModal?.(false)}
                  />
                ) : (
                  <div id={option.id} />
                )}
              </div>
            </Modal>
          )
        })}
      </div>
    </Layout>
  )
}

export default InquireNow
