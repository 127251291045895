const removeChatUI = (removeScript: string): void => {
  let script
  if (removeScript == 'afterSales') {
    script = document.querySelector('#afterSalesSE')
    script?.remove()
  } else {
    script = document.querySelector('#secDefaultSE')
    script?.remove()
  }

  document
    .querySelector(
      'script[src="//storage.googleapis.com/code.snapengage.com/js/f254e533-9c89-4b11-bf1c-58ab5cdc570c.js"]',
    )
    ?.remove()
  document.getElementById('designstudio')?.remove()
  document.getElementById('designstudio-minimize')?.remove()
  document.getElementById('designstudio-button')?.remove()
}

export const loadDefaultSE = (): void => {
  const defaultSESnippet = `
        (function() {
        var se = document.createElement('script'); se.type = 'text/javascript'; se.async = true; se.id = 'secDefaultSE'
        se.src = "https://storage.googleapis.com/code.snapengage.com/js/f254e533-9c89-4b11-bf1c-58ab5cdc570c.js";
        var done = false;
        se.onload = se.onreadystatechange = function() {
            if (!done&&(!this.readyState||this.readyState==='loaded'||this.readyState==='complete')) {
            done = true;
            /* Place your SnapEngage JS API code below */
            /* SnapEngage.allowChatSound(true); Example JS API: Enable sounds for Visitors. */
            }
        };
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(se, s);
        })()
    `

  if (document.getElementById('defaultSE')) {
    return
  }

  const container = document.createElement('div')
  container.setAttribute('id', 'defaultSE')

  const script = document.createElement('script')
  script.text = defaultSESnippet

  container.appendChild(script)
  document.body.appendChild(container)
}

export const removeDefaultSE = (): void => {
  const defaultSnapEngage = document.getElementById('defaultSE')

  if (defaultSnapEngage) {
    defaultSnapEngage.remove()
    removeChatUI('defaultWidget')
    loadAfterSalesSE()
  }
}

export const loadAfterSalesSE = (): void => {
  const afterSalesSESnippet = `
        (function() {
        var afse = document.createElement('script'); afse.type = 'text/javascript'; afse.async = true; afse.id = 'afterSalesSE'
        afse.src = "https://storage.googleapis.com/code.snapengage.com/js/58ef72da-869b-4689-b41f-032fbe028bd5.js";
        var done = false;
        afse.onload = afse.onreadystatechange = function() {
            if (!done&&(!this.readyState||this.readyState==='loaded'||this.readyState==='complete')) {
            done = true;
            /* Place your SnapEngage JS API code below */
            /* SnapEngage.allowChatSound(true); Example JS API: Enable sounds for Visitors. */
            }
        };
        var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(afse, s);
        })()
    `

  if (document.getElementById('afterSalesSE')) {
    return
  }

  const container = document.createElement('div')
  container.setAttribute('id', 'afterSalesSE')

  const script = document.createElement('script')
  script.text = afterSalesSESnippet

  container.appendChild(script)
  document.body.appendChild(container)
}

export const removeAfterSalesSE = (): void => {
  const afterSalesSE = document.getElementById('afterSalesSE')

  if (afterSalesSE) {
    afterSalesSE.remove()
    removeChatUI('afterSales')
    loadDefaultSE()
  }
}
