const removeCharacter = (text: string, position: number): string => {
  const part1 = text.substring(0, position)
  const part2 = text.substring(position + 1, text.length)

  return part1 + part2
}

const removeTextTag = (elementId: string): void => {
  const element: HTMLElement | null = document.getElementById(elementId)
  const isElement = (input: HTMLElement | null): input is HTMLElement =>
    input !== null

  if (isElement(element)) {
    const inputValue = document.getElementById(elementId) as HTMLInputElement

    const isElementInput = (
      input: HTMLInputElement | null,
    ): input is HTMLInputElement => input !== null

    if (isElementInput(inputValue)) {
      const currentPosition: number | null = inputValue.selectionStart
      inputValue.value = removeCharacter(
        inputValue.value,
        (currentPosition as number) - 1,
      )
    }
  }
}

const clearTextTag = (elementId: string): void => {
  const element: HTMLElement | null = document.getElementById(elementId)
  const isElement = (input: HTMLElement | null): input is HTMLElement =>
    input !== null

  if (isElement(element)) {
    const inputValue = document.getElementById(elementId) as HTMLInputElement

    const isElementInput = (
      input: HTMLInputElement | null,
    ): input is HTMLInputElement => input !== null

    if (isElementInput(inputValue)) {
      inputValue.value = ''
    }
  }
}

export const numericOnly = (event: any): void => {
  const key = event.data
  if (!event.inputType) {
    clearTextTag(event.target.id)
    return
  }

  const reg = /[^0-9\b]/
  if (reg.test(key) !== false && event.data) {
    removeTextTag(event.target.id)
  }
}

export const alphaOnly = (event: any): void => {
  /* eslint-disable no-console */
  const key = event.data
  if (!event.inputType) {
    clearTextTag(event.target.id)
    return
  }

  const reg = /^[a-z˜ñÑ.\-\b\s]+$/i
  if (reg.test(key) === false && event.data) {
    removeTextTag(event.target.id)
  }
}

export const alphaNumericWithSpecialCharacters = (event: any): void => {
  const key = event.data
  if (!event.inputType) {
    clearTextTag(event.target.id)
    return
  }

  const reg = /[^a-zA-Z˜ñÑ0-9.,?!\b.\s]/
  if (reg.test(key) !== false && event.data) {
    removeTextTag(event.target.id)
  }
}

export const preventInput = (event: any): void => {
  event.preventDefault()
}

export const addFormValidation = (elemetId: string, elements: any): void => {
  const hubspotForm: HTMLElement | null = document.getElementById(elemetId)

  if (hubspotForm) {
    Object.keys(elements).forEach((i) => {
      const currentElement: HTMLElement | null = document.getElementById(
        elements[i].elementId,
      )

      const isElement = (input: HTMLElement | null): input is HTMLElement =>
        input !== null

      if (isElement(currentElement)) {
        // add aplha only
        if (elements[i].validationType === 'alphaOnly') {
          currentElement.addEventListener('input', alphaOnly, true)
        }

        // add alphaNumericWithSpecialCharacters only
        if (
          elements[i].validationType === 'alphaNumericWithSpecialCharacters'
        ) {
          currentElement.addEventListener(
            'input',
            alphaNumericWithSpecialCharacters,
            true,
          )
        }

        // add numericOnly only
        if (elements[i].validationType === 'numericOnly') {
          currentElement.addEventListener('input', numericOnly, true)
        }

        currentElement.addEventListener('paste', preventInput, true)
      }
    })
  }
}
